@import "../variables.scss";

.Header {
    // max-width: var(--content-width);
    // margin: auto;
    @include content-column;

    padding-top: 200px;

    font-family: Nunito;

    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
}

.MainContent {
    max-width: var(--content-width);
    margin: auto;

    margin-top: 110px;
}

.ProjectArchive {
    @include content-column;
    margin-top: var(--portfolios-spacing-spacing-m);

    .ProjectArchive__Header {
        h1 {
            color: var(--portfolios-colors-primary-text);
            font-family: Nunito;
            font-size: 32px;
            font-weight: bold;
            line-height: 48px;
        }

        .ProjectArchive__Header__Arrow {
            color: var(--portfolios-colors-accent-static);
            font-family: Nunito;
            font-size: 32px;
            font-weight: bold;
            line-height: 48px;
        }
    }

    .ProjectArchive__Cards {
        margin-top: var(--portfolios-spacing-spacing-s);

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: var(--portfolios-spacing-spacing-s);

        .ProjectCard {
            display: flex;
            flex-direction: column;

            width: 310px;
            min-height: 100%;

            border: 1px solid;
            border-color: var(--colors-accent-static);
            border-radius: 20px;

            transition: border-color 0.2s ease-in-out,
                box-shadow 0.2s ease-in-out;

            &.ProjectCard__Link:hover {
                border-color: var(--secondary-color);
                box-shadow: 0 0 3px 3px var(--secondary-color);

                .ProjectCard__Top {
                    border-color: var(--secondary-color);
                }
            }

            .ProjectCard__Top {
                height: 200px;

                border-radius: 20px 20px 0px 0px;
                border-bottom: 1px solid;
                border-color: var(--colors-accent-static);

                transition: border-color 0.2s ease-in-out;

                overflow: hidden;
                //                 display: flex;
                //                 justify-content: center;
                //                 align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .ProjectCard__Bottom {
                // height: 150px;
                flex-grow: 1;

                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;

                text-align: center;
                padding: 25px 40px;

                // border: 1px solid;
                // border-color: var(--portfolios-colors-accent-static);
                // border-radius: 0px 0px 20px 20px;
            }
        }
    }
}

.Reviews {
    @include content-column;

    margin-top: 150px;

    //     align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--portfolios-spacing-spacing-s);
    //     justify-content: center;
    //     padding: var(--portfolios-spacing-spacing-none)
    //         var(--portfolios-spacing-spacing-l)
    //         var(--portfolios-spacing-spacing-none)
    //         var(--portfolios-spacing-spacing-l);

    .Reviews__Header {
        //         padding: var(--portfolios-spacing-spacing-none)
        //             var(--portfolios-spacing-spacing-l)
        //             var(--portfolios-spacing-spacing-none)
        //             var(--portfolios-spacing-spacing-l);

        h1 {
            color: var(--portfolios-colors-primary-text);
            font-family: var(--nunito-40-b-font-family);
            font-size: var(--nunito-40-b-font-size);
            font-style: var(--nunito-40-b-font-style);
            font-weight: var(--nunito-40-b-font-weight);
            letter-spacing: var(--nunito-40-b-letter-spacing);
            line-height: var(--nunito-40-b-line-height);
        }
    }

    .Reviews__Cards {
        display: flex;
        flex-direction: column;
        gap: var(--portfolios-spacing-spacing-s);
        align-items: center;
        // justify-content: center;

        .ReviewCard {
            //             width: 1320px;
            max-width: 1000px;

            border: 1px solid;
            border-color: var(--colors-accent-static);
            border-radius: 20px;

            //             align-self: stretch;
            //             color: var(--portfolios-colors-primary-text);
            //             font-family: var(--roboto-20-r-font-family);
            //             font-size: var(--roboto-20-r-font-size);
            //             font-style: var(--roboto-20-r-font-style);
            //             font-weight: var(--roboto-20-r-font-weight);
            //             letter-spacing: var(--roboto-20-r-letter-spacing);
            //             line-height: var(--roboto-20-r-line-height);
            //             margin-top: -1px;
            text-align: center;

            .ReviewCard__Top {
                //                 align-items: center;
                //                 background: linear-gradient(
                //                     180deg,
                //                     rgba(191, 215, 234, 0.12) 0%,
                //                     rgba(191, 215, 234, 0.03) 100%
                //                 );
                //                 background-color: rgba(255, 255, 255, 1);
                // border: 1px solid;
                border-bottom: 1px solid;
                border-color: var(--portfolios-colors-accent-static);
                // border-radius: 20px 20px 0px 0px;
                //                 justify-content: center;
                padding: 30px 50px;

                display: flex;
                flex-direction: column;
                gap: 10px;
            }

            .ReviewCard__Bottom {
                //                 align-items: center;
                //                 background: linear-gradient(
                //                     180deg,
                //                     rgba(191, 215, 234, 0.12) 0%,
                //                     rgba(191, 215, 234, 0.03) 100%
                //                 );
                //                 background-color: rgba(255, 255, 255, 1);
                // border: 1px solid;
                // border-color: var(--portfolios-colors-accent-static);
                // border-radius: 0px 0px 20px 20px;
                justify-content: center;
                padding: 30px 50px;
            }
        }
    }
}

.Section__Component {
    @include content-column;

    margin-top: 150px;

    display: flex;
    gap: 100px;
    align-items: flex-start;

    font-size: 20px;
    line-height: 23px;
}

.Section__Text {
    max-width: 1050px;

    display: flex;
    flex-direction: column;
    gap: var(--portfolios-spacing-spacing-xs);
}

.Section__Header {
    display: flex;
    flex-direction: column;
    gap: var(--portfolios-spacing-spacing-xs);

    min-width: 250px;

    .Section__Header__Index {
        font-family: var(--roboto-20-b-font-family);
        font-size: var(--roboto-20-b-font-size);
        font-style: var(--roboto-20-b-font-style);
        font-weight: var(--roboto-20-b-font-weight);
    }

    .Section__Header__Title {
        color: var(--colors-accent-static);

        font-family: var(--roboto-20-b-font-family);
        font-size: var(--roboto-20-b-font-size);
        font-style: var(--roboto-20-b-font-style);
        font-weight: var(--roboto-20-b-font-weight);
    }

    .Section__Header__Description {
        font-family: var(--nunito-25-b-font-family);
        font-size: var(--nunito-25-b-font-size);
        font-style: var(--nunito-25-b-font-style);
        font-weight: var(--nunito-25-b-font-weight);
        line-height: var(--nunito-25-b-line-height);
    }
}

.Section__TextWithHeader {
    max-width: 1050px;

    display: flex;
    gap: var(--portfolios-spacing-spacing-s);

    .Section__TextWithHeader__Content {
        display: flex;
        flex-direction: column;
        gap: var(--portfolios-spacing-spacing-xs);
    }
}

.Section__BannerText {
    margin-top: 150px;
    background-color: var(--highlight-color-translucent);

    .Section__BannerText__Inner {
        @include content-column;

        padding-top: var(--portfolios-spacing-spacing-sm);
        padding-bottom: var(--portfolios-spacing-spacing-sm);

        font-size: 16px;
        line-height: 24px;

        display: flex;
        flex-direction: column;
        gap: var(--portfolios-spacing-spacing-xs);

        .Section__BannerText__Content {
            font-family: var(--nunito-40-b-font-family);
            font-size: 24px;
            font-style: var(--nunito-40-b-font-style);
            font-weight: var(--nunito-40-b-font-weight);
            line-height: 29px;

            display: flex;
            flex-direction: column;
            gap: var(--portfolios-spacing-spacing-xs);
        }
    }
}

.Section__ImageGallery {
    margin: 0 auto;

    .Section__ImageGallery__Inner {
        @include content-column;

        .Section__ImageGallery__Content {
            display: flex;
            flex-direction: row;
            gap: var(--portfolios-spacing-spacing-s);
            justify-content: center;

            // .Section__ImageGallery__Image {
            //     border-radius: 10px;
            //     filter: var(--case-study-photo);

            img {
                &.ImageGallery__Image_WithBorder {
                    border-radius: 10px;
                    border: 1px solid var(--colors-primary-text);
                }

                &.ImageGallery__Image_WithShadow {
                    filter: var(--case-study-photo);
                }
            }
            // }
        }

        .Section__ImageGallery__Caption {
            margin-top: var(--portfolios-spacing-spacing-s);
            text-align: center;

            font-family: var(--roboto-24-i-font-family);
            font-size: var(--roboto-24-i-font-size);
            font-style: var(--roboto-24-i-font-style);
            font-weight: var(--roboto-24-i-font-weight);
            line-height: var(--roboto-24-i-line-height);
        }
    }
}

.Section__Callout {
    max-width: 245px;

    padding: var(--portfolios-spacing-spacing-s);

    border: 1px solid;
    border-radius: 20px;

    font-style: italic;
    font-size: 16px;

    display: flex;
    flex-direction: column;
    gap: 18px;

    .Tag {
        width: 141px;
        height: 142px;

        position: absolute;

        margin-top: -105px;
        margin-left: -105px;
    }

    &.ReadMeFirst {
        border-color: var(--highlight-color);
        background-color: var(--highlight-color-translucent);

        .Tag {
            background-image: url(../vectors/read-me-first.svg);
        }
    }

    &.NoteToSelf {
        border-color: var(--secondary-color);
        background-color: var(--secondary-color-translucent);

        .Tag {
            background-image: url(../vectors/note-to-self.svg);
        }
    }
}
