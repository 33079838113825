@import "./reset.css";
@import "./variables.scss";

b {
    font-weight: bold;
}

i {
    font-style: italic;
}

u {
    text-decoration: underline;
}

a {
    text-decoration: none;
    color: inherit;
}

html {
    // transform-origin: 50vw 0;
    // transform: scale(var(--global-scale));

    // width: var(--content-width);

    // width: 100vw;
    background-color: var(--off-white-color);
}

.Root {
    // position: absolute;
    // top: 0;

    // width: 100%;

    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 1.2;

    background-color: var(--off-white-color);
    color: var(--primary-text-color);
}

.App {
    max-width: var(--page-width);
    // max-width: 1000px;
    margin: auto;
}

.Content {
    // margin-top: 252px;
}
