@import "../variables.scss";

.Masthead {
    position: fixed;
    width: 100%;
    top: 0;

    // position: fixed;
    z-index: 999;

    // top: 0;
    // width: 100vw;
    // height: 200px;

    // padding-top: 42px;

    font-family: Nunito;

    background: linear-gradient(
        var(--off-white-color) 120px,
        transparent 200px
    );

    display: flex;
    justify-content: space-between;
}

.Link {
    @include red-on-hover("color");
}

.Brand {
    img {
        height: 200px;
    }
}

nav {
    font-weight: bold;
    font-size: 18px;
    line-height: 34px;

    display: flex;

    gap: 30px;

    margin-top: 25px;
    margin-right: 80px;
}

nav {
    //     float: right;

    //     margin-top: 25px;
    //     // margin-right: 80px;

    //     font-weight: bold;
    //     font-size: 25px;
    //     line-height: 34px;

    //     a:not(:first-child) {
    //         margin-left: 37px;
    //     }

    // .Tabs__CurrentPage {
    //     position: relative;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     display: inline-block;

    //     top: calc(100% + 10px);
    //     left: calc(50% - 4px);

    //     width: 8px;
    //     height: 8px;
    //     background-color: var(--highlight-color);

    //     border-radius: 50%;
    // }

    .Tabs__CurrentPageIndicator {
        display: flex;
        justify-content: center;

        .Tabs__CurrentPageIndicator__Dot {
            width: 8px;
            height: 8px;
            background-color: var(--highlight-color);

            border-radius: 50%;
        }
    }
}
// }
