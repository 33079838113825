@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,700;1,700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

:root {
    --off-white-color: #fffdf5;
    --off-black-color: #042a2b;

    --highlight-color: #00a6ed;
    --highlight-color-translucent: rgba(0, 166, 237, 0.1);
    --lowlight-color: #e9faff;

    --secondary-color: rgb(255, 151, 168);
    --secondary-color-translucent: rgb(255, 151, 168, 0.1);

    --secondary-text-color: #7700d4;
    --grey-text-color: #5c686c;

    --colors-primary-text: #414066;
    --colors-accent-static: #00a6ed;

    --page-width: 1920px;
    --content-width: 1200px;
    --content-min-padding: 50px;

    --intro-image-h-offset: 150px;

    // --case-study-header-photo: 15px 15px 0px 0px rgba(0, 166, 237, 1);
    --case-study-header-photo: drop-shadow(15px 15px rgba(0, 166, 237, 1));
    --case-study-photo: drop-shadow(15px 15px rgba(240, 250, 254, 1));
    --glass-blur: ;
    --nunito-15-b-font-family: "Nunito";
    --nunito-15-b-font-size: 15px;
    --nunito-15-b-font-style: normal;
    --nunito-15-b-font-weight: 700;
    --nunito-15-b-letter-spacing: 0px;
    --nunito-15-b-line-height: normal;
    --nunito-25-b-font-family: "Nunito";
    --nunito-25-b-font-size: 25px;
    --nunito-25-b-font-style: normal;
    --nunito-25-b-font-weight: 700;
    --nunito-25-b-letter-spacing: 0px;
    --nunito-25-b-line-height: normal;
    --nunito-40-b-font-family: "Nunito";
    --nunito-40-b-font-size: 40px;
    --nunito-40-b-font-style: normal;
    --nunito-40-b-font-weight: 700;
    --nunito-40-b-letter-spacing: 0px;
    --nunito-40-b-line-height: normal;
    --nunito-50-b-font-family: "Nunito";
    --nunito-50-b-font-size: 50px;
    --nunito-50-b-font-style: normal;
    --nunito-50-b-font-weight: 700;
    --nunito-50-b-letter-spacing: 0px;
    --nunito-50-b-line-height: normal;
    --portfolios-colors-accent-interactive: rgba(255, 151, 168, 1);
    --portfolios-colors-accent-static: rgba(0, 166, 237, 1);
    --portfolios-colors-primary-background: rgba(255, 253, 245, 1);
    --portfolios-colors-primary-text: rgba(65, 64, 102, 1);
    --portfolios-colors-secondary-background: rgba(233, 250, 255, 1);
    --portfolios-colors-secondary-text: rgba(123, 123, 136, 1);
    --portfolios-finishing-corner-lg: 20px;
    --portfolios-spacing-height-photo: 450px;
    --portfolios-spacing-spacing-l: 300px;
    --portfolios-spacing-spacing-m: 150px;
    --portfolios-spacing-spacing-none: 0px;
    --portfolios-spacing-spacing-s: 32px;
    --portfolios-spacing-spacing-sm: 64px;
    --portfolios-spacing-spacing-xs: 24px;
    --portfolios-spacing-spacing-xxs: 16px;
    --roboto-12-i-font-family: "Roboto";
    --roboto-12-i-font-size: 12px;
    --roboto-12-i-font-style: italic;
    --roboto-12-i-font-weight: 400;
    --roboto-12-i-letter-spacing: 0px;
    --roboto-12-i-line-height: normal;
    --roboto-12-m-font-family: "Roboto";
    --roboto-12-m-font-size: 12px;
    --roboto-12-m-font-style: normal;
    --roboto-12-m-font-weight: 500;
    --roboto-12-m-letter-spacing: 0px;
    --roboto-12-m-line-height: normal;
    --roboto-12-r-font-family: "Roboto";
    --roboto-12-r-font-size: 12px;
    --roboto-12-r-font-style: normal;
    --roboto-12-r-font-weight: 400;
    --roboto-12-r-letter-spacing: 0px;
    --roboto-12-r-line-height: 15px;
    --roboto-15-b-font-family: "Roboto";
    --roboto-15-b-font-size: 13px;
    --roboto-15-b-font-style: normal;
    --roboto-15-b-font-weight: 700;
    --roboto-15-b-letter-spacing: 0px;
    --roboto-15-b-line-height: normal;
    --roboto-20-b-font-family: "Roboto";
    --roboto-20-b-font-size: 20px;
    --roboto-20-b-font-style: normal;
    --roboto-20-b-font-weight: 700;
    --roboto-20-b-letter-spacing: 0px;
    --roboto-20-b-line-height: normal;
    --roboto-20-i-font-family: "Roboto";
    --roboto-20-i-font-size: 20px;
    --roboto-20-i-font-style: italic;
    --roboto-20-i-font-weight: 400;
    --roboto-20-i-letter-spacing: 0px;
    --roboto-20-i-line-height: normal;
    --roboto-20-r-font-family: "Roboto";
    --roboto-20-r-font-size: 20px;
    --roboto-20-r-font-style: normal;
    --roboto-20-r-font-weight: 400;
    --roboto-20-r-letter-spacing: 0px;
    --roboto-20-r-line-height: normal;
    --roboto-24-i-font-family: "Roboto";
    --roboto-24-i-font-size: 24px;
    --roboto-24-i-font-style: italic;
    --roboto-24-i-font-weight: 400;
    --roboto-24-i-letter-spacing: 0px;
    --roboto-24-i-line-height: normal;
    --roboto-24-r-font-family: "Roboto";
    --roboto-24-r-font-size: 24px;
    --roboto-24-r-font-style: normal;
    --roboto-24-r-font-weight: 400;
    --roboto-24-r-letter-spacing: 0px;
    --roboto-24-r-line-height: normal;

    // --global-scale: 0.8;
}

@mixin content-column {
    max-width: var(--content-width);
    padding-left: var(--content-min-padding);
    padding-right: var(--content-min-padding);
    margin: auto;

    // min-width: 375px;
}

body {
    color: var(--colors-primary-text);
}

@mixin on-hover($property, $value) {
    transition: #{$property} 0.2s ease-in-out;

    &:hover {
        #{$property}: #{$value};
    }
}

@mixin red-on-hover($property) {
    @include on-hover($property, var(--secondary-color));
}
