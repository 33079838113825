@import "../variables.scss";

.Introduction {
    margin-top: 230px;
    padding: 64px 0;

    background-color: var(--lowlight-color);

    a {
        color: var(--colors-accent-static);
        text-decoration: underline;
    }
}

.FacePhoto {
    // display: inline-block;
    // position: absolute;
    // margin-left: 200px;
    // margin-top: 16px;

    padding-bottom: 30px;

    .FacePhoto__Image {
        // overflow: hidden;
        max-width: 212px;
        // height: 345px;

        // box-shadow: 15px 15px var(--highlight-color);

        img {
            width: 100%;
            border-radius: 20px;
            // height: 100%;
        }
    }
}

.Content {
    margin-top: 64px;

    a {
        color: var(--colors-accent-static);
        text-decoration: underline;
    }
}

.Section {
    max-width: 640px;
    margin: 0 auto;

    .Content & {
        margin-top: 64px;
    }

    font-size: 16px;
    line-height: 24px;
}

.Section__Title {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;

    color: var(--highlight-color);
}

.Section__Content {
    p {
        margin-top: 16px;
    }
}

.Reviews {
    margin: 150px auto 250px auto;
    width: 1250px;
}

.Reviews__Title {
    font-family: Nunito;
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
}

.Reviews__Content {
    margin-top: 100px;
}

.Review {
    font-size: 20px;
    line-height: 23px;

    border: 2px solid var(--highlight-color);
    border-radius: 20px;

    &:not(:first-child) {
        margin-top: 50px;
    }
}

.Review__Text {
    padding: 30px 50px;

    border-bottom: 2px solid var(--highlight-color);
}

.Review__By {
    padding: 15px 50px;
}
