@import "../variables.scss";

.Introduction {
    //     margin-left: 300px;
    //     margin-top: 400px;

    //     width: 1225px;

    position: absolute;
    height: 100vh;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 0;

    .Introduction__Content {
        @include content-column;
        max-width: 1000px;

        display: flex;

        font-family: Nunito;
        font-weight: bold;
        font-size: 32px;

        .Introduction__Left {
            width: fit-content;
            white-space: nowrap;

            margin-right: 0.25em;

            .Introduction__Hi {
                font-style: italic;
            }
        }

        .Introduction__Right {
            display: inline-block;
        }

        .Introduction__Static {
            margin-top: 50px;

            font-family: Roboto;
            font-size: 21px;
            line-height: 25px;
            font-style: italic;
            font-weight: 400;

            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}

.Introduction__Rotating {
}

.Carousel__Blurb {
    position: absolute;
}

// .Carousel__Blurb {
//     animation-duration: 2.5s;
//     animation-iteration-count: infinite;

//     height: 100%;

//     &:first-child {
//         animation-name: TextCarouselFadeIn;
//     }

//     &:last-child {
//         animation-name: TextCarouselFadeOut;
//     }
// }

// @keyframes TextCarouselFadeOut {
//     0%,
//     80% {
//         opacity: 1;
//         transform: translateY(-100%);
//     }

//     100% {
//         animation-timing-function: ease-out;

//         opacity: 0;
//         transform: translateY(0);
//     }
// }

// @keyframes TextCarouselFadeIn {
//     0%,
//     80% {
//         opacity: 0;
//         transform: translateY(-100%);
//     }

//     100% {
//         animation-timing-function: ease-out;

//         opacity: 1;
//         transform: translateY(0);
//     }
// }

// .Introduction__Icon {
//     display: inline-block;
//     vertical-align: top;
//     width: 44px;

//     &:not(:first-child) {
//         margin-left: 20px;
//     }

//     img {
//         display: block;
//     }
// }

// .Introduction__Accent {
//     padding-left: 1288px;
//     vertical-align: top;

//     img {
//         display: block;
//     }
// }

#AboveFold {
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.ActionButton {
    // top: calc(100vh - 100px);
    // position: absolute;
    // bottom: 100vh;

    z-index: 998;
}

#BelowFold {
    /* keep */
    margin-top: 50px;
}

hr.Spacer {
    border: none;

    background-color: var(--lowlight-color);
    height: 8px;
}

.ActionIcon {
    margin: 40px 0;
    text-align: center;
}

// .Projects {
//     // TODO: adjust this so main content is on the next page
//     margin-top: 240px;
// }

// .Section {
//     text-align: center;

//     &:not(:first-child) {
//         margin-top: 200px;
//     }
// }

// .Section__Image {
//     display: inline-block;
//     vertical-align: top;
//     overflow: hidden;

//     height: 550px;

//     border: 2px solid var(--highlight-color);
//     border-radius: 10px;
//     box-shadow: 15px 15px var(--highlight-color);

//     img {
//         width: 100%;
//         height: 100%;
//     }
// }

// .Section__Content {
//     display: inline-block;

//     &:first-child {
//         margin-right: 150px;
//     }

//     &:last-child {
//         margin-left: 150px;
//     }

//     width: 522px;

//     text-align: left;
//     // TODO: figure out how to center this vertically
//     vertical-align: middle;
// }

// .Section__Title {
//     font-family: Nunito;
//     font-weight: bold;
//     font-size: 25px;
//     line-height: 34px;
// }

// .Section__Text {
//     margin-top: 20px;

//     font-size: 24px;
//     line-height: 28px;
// }

// .EnterProjectButton {
//     display: inline-block;

//     padding: 15px 40px;

//     border: 2px solid var(--highlight-color);
//     border-radius: 50px;

//     margin-top: 30px;

//     background-color: var(--off-white-color);
//     color: var(--highlight-color);
//     font-size: 24px;
//     line-height: 28px;
// }

// .MobileApp .Section__Image {
//     // TODO: not as tight as the image
//     border-radius: 35px;
// }

.ProjectArchive {
    margin-top: 150px;
}

// .ProjectArchive__Title {
//     h1 {
//         font-family: Nunito;
//         font-weight: bold;
//         font-size: 40px;
//         line-height: 55px;
//     }
// }

// .ProjectArchive__Grid {
//     display: grid;
//     gap: 100px;
//     grid-template-columns: repeat(3, 1fr);

//     margin-top: 80px;
//     margin-bottom: 350px;
// }

// .ProjectArchive__Cell {
//     border: 2px solid var(--highlight-color);
//     border-radius: 20px 20px;

//     overflow: hidden;

//     .ProjectArchive__Cell__Image {
//         height: 200px;

//         overflow: hidden;

//         border-bottom: 2px solid var(--highlight-color);
//     }

//     img {
//         width: 100%;
//     }

//     .ProjectArchive__Cell__Caption {
//         font-size: 20px;
//         line-height: 23px;

//         padding: 30px 50px;
//     }
// }
