@import "../variables.scss";

.ExternalLinkIntro {
    text-decoration: underline;
}

.ExternalLinkContent {
    text-decoration: underline;
    color: var(--colors-accent-static);
}

.ExternalLinkCallout {
    text-decoration: underline;
    color: var(--colors-accent-static);
    font-style: normal;
}

.ExternalLink {
    @include red-on-hover("color");
}

.ExternalLinkFooter {
    &:hover {
        // TODO: the underline only changes color when highlighted?
        text-decoration-color: var(--secondary-color);
    }
}
