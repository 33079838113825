@import "../variables.scss";

footer {
    background-image: url(../vectors/footer.svg);
    // background-size: cover;
    background-size: auto;

    color: var(--off-white-color);
    font-family: Nunito;
    font-weight: bold;
    font-size: 18px;

    // line-height: 34px;

    // width: 100vw;
    // // height: 640px;

    margin-top: 150px;

    .FooterContent {
        padding: 100px;
        padding-top: 150px;

        max-width: 900px;

        display: flex;
        flex-direction: column;
        gap: var(--portfolios-spacing-spacing-xs);
    }

    h1 {
        font-size: 36px;
        line-height: 68.2px;
    }

    .FooterLinks {
        display: flex;
        gap: var(--portfolios-spacing-spacing-s);
        font-style: italic;
        text-decoration: underline;
    }
}
