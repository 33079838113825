@import "../variables.scss";

.Introduction {
    background-color: var(--lowlight-color);

    margin-top: var(--portfolios-spacing-spacing-sm);
}

.Content {
    @include content-column;

    padding-top: var(--portfolios-spacing-spacing-sm);
    padding-bottom: var(--portfolios-spacing-spacing-sm);

    max-width: var(--content-width);
    // max-width: calc(var(--content-width) + var(--intro-image-h-offset));

    display: flex;
    gap: var(--portfolios-spacing-spacing-s);
}

.TextContent {
    display: flex;
    flex: 1 1 0;
    gap: var(--portfolios-spacing-spacing-xs);

    min-width: 575px;
}

.Column {
    //     // display: inline-block;
    //     vertical-align: top;

    // width: 300px;
    // width: 0;

    //     // &:not(:first-child) {
    //     //     margin-left: 50px;
    //     // }

    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 30px;
}

.Image {
    // flex: 1;

    padding-left: 50px;

    img {
        width: 100%;
        // height: auto;
        // object-fit: contain;

        border-radius: 10px;

        &.Image_WithShadow {
            filter: var(--case-study-header-photo);
        }

        &.Image_WithBorder {
            border: 1px solid black;
        }
    }
}

.ImageGallery {
    display: flex;
    gap: var(--portfolios-spacing-spacing-xs);

    .Image {
        // max-width: 200px;

        // &:first-child {
        //     margin-left: 100px;
        // }

        width: auto;
        height: 100%;
        object-fit: cover;
    }
}

// // .Image {
// //     position: relative;
// //     display: inline-block;

// //     img {
// //         position: absolute;

// //         top: -120px;
// //         left: 105px;
// //         height: 500px;

// //         border-radius: 10px;

// //         box-shadow: 15px 15px var(--highlight-color);
// //     }
// // }

// .Part:not(:first-child) {
//     margin-top: 30px;
// }

.Part__Title {
    font-weight: bold;
    color: var(--highlight-color);
}

.Part__Content p {
    margin-top: 10px;

    font-size: 18px;
}
